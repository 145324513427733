<template>
  <div class="address-list">
    <div class="address-list-cont">
      <div class="address-title">Address List</div>
      <div class="search">
        <a-space>
          <a-input-search :style="{ width: '320px' }" placeholder="Enter keyword" search-button @search="searchAddress"
            ref="searchBar" v-model="inputVal" />
          <a-button type="primary" shape="round" @click="goAdd">+ Add New
          </a-button>
        </a-space>
      </div>
      <div class="address-menu">
        <a-menu mode="horizontal" @menu-item-click="clickMenu" :selected-keys="[navselect]">
          <a-menu-item :key="1">Sender</a-menu-item>
          <a-menu-item :key="2">Recipient</a-menu-item>
          <a-menu-item :key="3">Return</a-menu-item>
        </a-menu>
      </div>
      <!-- 表格 -->
      <div class="mytable">
        <a-config-provider :locale="enUS">
          <a-spin :loading="loading" tip="Loading...">
            <a-table column-resizable :columns="navselect === 2 ? reColumns : listColumns" :data="listData.value"
              :row-selection="rowSelection" :pagination="false" :bordered="false" :scroll="{ y: tableHeight.value }"
              @select-all="selsectAll" @select="selectVal" :selected-keys="selectArr.val">
              <template #empty>
                <div v-if="listData.value.length === 0 && loading === false"
                  :style="{ height: tableHeight.value - 35 + 'px' }" class="empty-cont">
                  <!-- <a-empty /> -->
                  <img src="../../assets/images/empty/listempty.svg" alt="">
                  <div>No results found</div>
                </div>
                <div v-if="loading === true" :style="{ height: tableHeight.value - 35 + 'px' }">
                </div>
              </template>
              <template #isdefault="{ record }">
                <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="record.isdefault" :checked-value="1"
                  :unchecked-value="0" :disabled="record.isdefault === 1 ? true : false" @change="setDefault(record.key)">
                  <template #checked> ON </template>
                  <template #unchecked> OFF </template>
                </a-switch>
              </template>
              <template #street="{ record }">
                <div>{{ record.street1 }}</div>
                <div v-if="record.street2">{{ record.street2 }}</div>
              </template>
              <template #company="{ record }">
                <div v-if="record.company">{{ record.company }}</div>
                <div v-else>N/A</div>
              </template>
              <template #action="{ record }">
                <a-space>
                  <a-tooltip content="Edit">
                    <a-button shape="circle" @click="goEdit(record.key)">
                      <icon-edit />
                    </a-button>
                  </a-tooltip>
                  <a-popconfirm content="Are you sure you want to delete?" position="top" @ok="deleteAddress(record.key)"
                    ok-text="Delete" cancel-text="Cancel" class="deletePop" type="warning">
                    <a-tooltip content="Delete">
                      <a-button v-if="(listData.value.length > 1 && record.isdefault !== 1)" shape="circle">
                        <icon-delete />
                      </a-button>
                    </a-tooltip>
                  </a-popconfirm>
                </a-space>
              </template>
            </a-table>
          </a-spin>
        </a-config-provider>
      </div>
      <div class="page">
        <a-space>
          <span>Total : {{ importTotal }}</span>
          <a-button :disabled="currentpage == 1" @click="clickMinus" type="text">
            <icon-left />
          </a-button>
          <span class="currentpage">{{ currentpage }}</span>
          <div>/</div>
          <div>{{ totalPages }}</div>
          <a-button :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd" type="text">
            <icon-right />
          </a-button>
          <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
            <a-option :value="20">20 / Page</a-option>
            <a-option :value="50">50 / Page</a-option>
            <a-option :value="100">100 / Page</a-option>
            <a-option :value="200">200 / Page</a-option>
          </a-select>
        </a-space>
      </div>
    </div>
    <div class="bottom-footer" v-if="selectArr.val.length > 0">
      <a-space>
        <a-checkbox v-model="checkedAll" @change="selsectAll" class="itemNum">
          {{ selectArr.val.length }} items
        </a-checkbox>
        <a-popconfirm content="Are you sure you want to delete?" position="top" @ok="moreDelete" ok-text="Delete"
          cancel-text="Cancel" class="deletePop" type="warning">
          <a-button status="danger" shape="round" v-if="isDisable === false && selectArr.val.length > 0">Delete
          </a-button>
        </a-popconfirm>
      </a-space>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ref, reactive, onMounted } from 'vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { addressList, addressDelete, addressUpdateColumn, addressBatchDelete } from '@/api/address.js'
import { Notification } from '@arco-design/web-vue'
import {
  IconRight, IconLeft, IconEdit, IconDelete
} from '@arco-design/web-vue/es/icon'
const loading = ref(false)
const router = useRouter()
const route = useRoute()
const navselect = ref(1)
const searchBar = ref(null)
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
tableHeight.value = document.body.clientHeight - 350
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableHeight.value = document.body.clientHeight - 350
  }
})
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
const listColumns = [
  {
    title: 'Default',
    dataIndex: 'isdefault',
    slotName: 'isdefault',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Company',
    dataIndex: 'company',
    slotName: 'company',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Street Address',
    dataIndex: 'street',
    slotName: 'street',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Zip Code',
    dataIndex: 'zip',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'City',
    dataIndex: 'city',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'State',
    dataIndex: 'state',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    width: 160
  }
]
const reColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Company',
    dataIndex: 'company',
    slotName: 'company',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Street Address',
    dataIndex: 'street',
    slotName: 'street',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Zip Code',
    dataIndex: 'zip',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'City',
    dataIndex: 'city',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'State',
    dataIndex: 'state',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    width: 160
  }
]
// 搜索输入的内容
let inputVal = ref('')
// 表格数据
let listData = reactive({ value: [] })
// 当前页
const currentpage = ref(1)
const importTotal = ref(0)
const totalPages = ref(0)
const pageSize = ref(20)
// 获取地址列表
const getList = async () => {
  loading.value = true
  const msg = await addressList({
    page: currentpage.value,
    type: navselect.value,
    searchItem: inputVal.value,
    pageCount: pageSize.value
  })
  if (msg.code === 0) {
    loading.value = false
    inputVal.value = ''
    let info = msg.data.data
    importTotal.value = msg.data.total
    currentpage.value = msg.data.current_page
    totalPages.value = msg.data.page_count
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({
        key: info[i].id, isdefault: info[i].is_default, name: info[i].name, company: info[i].company, street1: info[i].street1, street2: info[i].street2, zip: info[i].zip, city: info[i].city, state: info[i].state, phone: info[i].phone
      })
    }
    listData.value = currentData
    if (listData.value.length === 1) {
      isDisable.value = true
    }
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 去修改
function goEdit (editId) {
  router.push('/address/editaddress?editId=' + editId)
}
let selectArr = reactive({
  val: []
})
let isDisable = ref(true)
const selsectAll = (checked) => {
  if (checked) {
    checkedAll.value = true
    selectArr.val = []
    listData.value.forEach((item) => {
      selectArr.val.push(item.key)
    })
    if (listData.value.every(item => { return item.isdefault === 0 })) {
      isDisable.value = false
    } else {
      isDisable.value = true
    }
  } else {
    checkedAll.value = false
    selectArr.val = []
    isDisable.value = true
  }
}
function selectVal (rowKeys) {
  selectArr.val = rowKeys
  if (rowKeys.length > 0) {
    let selectArr = []
    for (let i = 0; i < rowKeys.length; i++) {
      for (let j = 0; j < listData.value.length; j++) {
        if (rowKeys[i] === listData.value[j].key) {
          selectArr.push(listData.value[j])
        }
      }
    }
    if (selectArr.every(item => { return item.isdefault === 0 })) {
      isDisable.value = false
    } else {
      isDisable.value = true
    }
  } else {
    isDisable.value = true
  }
}
// 批量删除
const moreDelete = async () => {
  const msg = await addressBatchDelete({
    ids: selectArr.val
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Deleted.'
    })
    currentpage.value = 1
    listData.value = []
    getList()
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 单个删除
const deleteAddress = async (deleteId) => {
  const msg = await addressDelete({
    id: deleteId
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Deleted.'
    })
    currentpage.value = 1
    listData.value = []
    getList()
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 搜索
const searchAddress = (val) => {
  inputVal.value = val
  currentpage.value = 1
  listData.value = []
  getList()
}
// 设置默认
const setDefault = async (defaultId) => {
  const msg = await addressUpdateColumn({
    id: defaultId,
    column: "is_default",
    value: "1"
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Seted.'
    })
    currentpage.value = 1
    listData.value = []
    getList()
  }
}
const clickMenu = (nav) => {
  navselect.value = nav
  currentpage.value = 1
  listData.value = []
  getList()
}
// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}
const selectPage = (val) => {
  pageSize.value = val
  getList()
}
const checkedAll = ref(false)
if (route.query.addressType) {
  navselect.value = Number(route.query.addressType)
  getList()
} else {
  navselect.value = 1
  getList()
}
const goAdd = () => {
  router.push('/address/addaddress?type=' + navselect.value)
}
</script>
<style lang="less" scoped>
.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.address-list-cont {

  .address-title {
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text-1);
    margin: 18px 24px 40px 48px;
  }

  .search {
    margin-left: 24px;
  }

  .address-menu {
    margin-left: 12px;

    :deep(.arco-menu-light .arco-menu-item.arco-menu-selected) {
      background-color: var(--color-fill-2);
    }

    :deep(.arco-menu-selected-label) {
      background-color: transparent;
    }

    :deep(.arco-menu-horizontal .arco-menu-inner) {
      padding: 24px 0;
    }
  }
}

.mytable {
  margin: 0 24px;
}

.bottom-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72px;
  padding-left: 20px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
}

.page {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  margin-right: 24px;
  padding-bottom: 24px;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}
</style>
<style>
.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
