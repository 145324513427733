// 引入封装的axios请求文件
import req from '../utils/request.js'

// 获取列表
export function addressList (params) {
  return req.post('addressList/v2', params)
}

// 添加地址
export function addressAdd (params) {
  return req.post('addressAdd', params)
}

// 单个删除
export function addressDelete (params) {
  return req.post('addressDelete', params)
}

// 批量删除
export function addressBatchDelete (params) {
  return req.post('addressBatchDelete', params)
}

// 设置默认
export function addressUpdateColumn (params) {
  return req.post('addressUpdateColumn', params)
}

// 获取详情
export function addressDetail (params) {
  return req.post('addressDetail', params)
}

// 自动识别
export function addressAnalysis (params) {
  return req.post('addressAnalysis', params)
}

// 修改地址
export function addressEdit (params) {
  return req.post('addressEdit', params)
}
